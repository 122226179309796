export default {
  colors: {
    primary: '#5E81AC',
    background: '#2E3440',
    shape: `#495263`,
    title: `#5E81AC`,
    text: `#fff`,
    components: {
      blockquote: {
        background: `#feebc8`,
        text: `#2d3748`,
      },
    },
  },
};
